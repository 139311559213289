import { connect } from "react-redux";
import ModalView from "./ModalView";

const mapStateToProps = ({ParamsReducer:{params}}, ownProps) => {
    if(params){
        return {...params, ...ownProps}
    }
    return { paymentDetails:{}, ...ownProps};
};

export default connect(mapStateToProps)(ModalView);
