import {flexColumn, flexRow} from "../flexStyles";
import {createFonts} from "../fonts";

const PreviewStyles = {
    previewContainer: {
        all:'initial',
        ...flexRow,
        flexWrap: 'wrap'
    },
    previewToolsContainer: {
        width: '30%',
        ...flexColumn,
        '@media (max-width: 1250px)':{
            width: '50%'
        },
        '@media (max-width: 800px)':{
            width: '100%',
            marginBottom: '16px'
        }
    },
    previewModeContainer: {
        ...flexRow,
        padding:'16px',
        alignItems: 'center',
        flexWrap: 'wrap',
        '@media (max-width: 1250px)':{
            width: '50%'
        },
        '@media (max-width: 800px)':{
            width: '100%'
        }
    },
    previewThemeContainer: {
        border: '#d4d4d4 solid 1px',
        marginTop: '16px',
        position: 'relative',
        padding:'8px'
    },
    previewThemeTitle: {
        ...createFonts('18px'),
        position:'absolute',
        top: '-13px',
        padding:'0 4px',
        backgroundColor: 'white',
        fontWeight:'600',
        color: '#373d47'
    },
    previewThemeSelectorContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    previewCheckoutComponentContainer: {
        width: '40%',
        position: 'relative',
        display:'flex',
        justifyContent:'center',
        '@media (max-width: 1250px)':{
            width: '50%'
        },
        '@media (max-width: 800px)':{
            width: '100%',
            marginBottom: '16px'
        }
    },
    previewJson: {
        ...createFonts('12px'),
        textAlign: 'initial',
        width: '30%',
        '@media (max-width: 800px)':{
            width: '100%'
        }
    }
}

export default PreviewStyles
