import React, {useEffect, useState} from 'react';
import {setupPayment, setupScript} from "../../utils/commonUtils";
import CheckoutComponent from "../../Components/CheckoutComponent";

const ModalView = ({paymentDetails, styles, theme, closeModal, transactionId, paymentType, customerEmailAddress}) => {
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)

    const publicApiKey = paymentDetails?.publicApiKey

    useEffect(() => {
        if (!hasLoadedScripts && publicApiKey) {
            setHasLoadedScrips(true)
            setupScript(window, publicApiKey)
        }
    }, [hasLoadedScripts, publicApiKey])

    if (!paymentDetails) {
        console.error('PaymentDetails field is mandatory.')
    }

    if (paymentDetails && !publicApiKey) {
        console.error('Public Api key is mandatory.')
        return null
    }

    const {amountToShow, currencyToShow} = paymentDetails
    const _customerEmailAddress = customerEmailAddress ? customerEmailAddress : paymentDetails.customerEmailAddress

    let startPaymentJourney = () => {
        if(publicApiKey) {
            const _transactionId = transactionId ? transactionId : paymentDetails.transactionId

            const newPaymentDetails = {
                ...paymentDetails,
                customerEmailAddress: _customerEmailAddress,
                transactionId: _transactionId
            }

            setupPayment(window, _customerEmailAddress, newPaymentDetails, paymentType)
            closeModal()

        } else {
            console.error('Public Api key is missing.')
        }
    }

    return (<CheckoutComponent startPaymentJourney={startPaymentJourney}
                               amount={amountToShow}
                               currency={currencyToShow}
                               styles={styles}
                               theme={theme}
                               paymentType={paymentType}
                               customerEmailAddress={_customerEmailAddress}/>)

};

export default ModalView;
