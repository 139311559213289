import { jss } from 'react-jss';
import sanFrancisco100 from '../assets/fonts/SF-Pro/SF-Pro-Text-Light.otf'
import sanFrancisco400 from '../assets/fonts/SF-Pro/SF-Pro-Text-Regular.otf'
import sanFrancisco500 from '../assets/fonts/SF-Pro/SF-Pro-Text-Medium.otf'
import sanFrancisco600 from '../assets/fonts/SF-Pro/SF-Pro-Text-Semibold.otf'
import sanFrancisco700 from '../assets/fonts/SF-Pro/SF-Pro-Text-Bold.otf'

import avenirProDemi from '../assets/fonts/AvenirNextLTPro-Demi.ttf'
import avenirProRegular from '../assets/fonts/AvenirNextLTPro-Regular.ttf'

const fontNames = {
    $citizenFontRegularSF: 'San Francisco',
    customFont: 'Custom Font'
}

jss.createStyleSheet({
    '@font-face': [
        {
            fontFamily: 'San Francisco',
            fontWeight: 100,
            src: `url('${sanFrancisco100}')`
        },
        {
            fontFamily: 'San Francisco',
            fontWeight: 400,
            src: `url('${sanFrancisco400}')`
        },
        {
            fontFamily: 'San Francisco',
            fontWeight: 500,
            src: `url('${sanFrancisco500}')`
        },
        {
            fontFamily: 'San Francisco',
            fontWeight: 600,
            src: `url('${sanFrancisco600}')`
        },
        {
            fontFamily: 'San Francisco',
            fontWeight: 700,
            src: `url('${sanFrancisco700}')`
        },
        {
            fontFamily: 'Avenir Next LT Pro Demi',
            src: `url('${avenirProDemi}')`

        },
        {
            fontFamily: 'Avenir Next LT Pro Regular',
            src: `url('${avenirProRegular}')`
        }
    ],
}).attach();

export const injectFonts = (fonts) => {
    return new Promise((resolve => {
        const styles = document.getElementsByTagName('style')
        let isAlreadyInjected = false
        for(let i in styles){
            if(styles[i].innerHTML === fonts){
                isAlreadyInjected = true
            }
        }

        if(!isAlreadyInjected) {
            let style = document.createElement('style');
            style.innerHTML = fonts
            window.document.body.appendChild(style);
        }
        resolve()
    }))

}

const fonts = {

    ...fontNames
}

export const createFonts = (fontSize) => {

    return ({
        fontFamily: fontNames.$citizenFontRegularSF,
        fontSize: fontSize,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
    })
}




export default fonts
