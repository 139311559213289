import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import CustomButton from "../../../Components/CustomButtons/CustomButton";
import {JssProvider} from "react-jss";
import NewWindow from "../../../Components/NewWindow";
import CheckoutComponent from "../../../Components/CheckoutComponent";
import {actions} from "../../../state/ParamsState";

const WindowedPreviewComponent = (props) => {
    const [showWindow, setShowWindow] = useState(false)
    const [parent, setParent] = useState(null)

    const {params} = props

    if (!params) {
        return null
    }

    const {paymentDetails, styles, theme} = params

    if (!paymentDetails) {
        console.error('Payment details are mandatory.')
        return null
    }

    const {customerEmailAddress, amountToShow, currencyToShow} = paymentDetails

    let startPaymentJourney = () => {}

    const handleOnCloseWindow = () => {
        setShowWindow(false)
        setParent(null)
    }

    const usageStyles = {
        fontFamily: 'San Francisco',
        marginTop: '8px',
        padding: '8px',
        borderRadius: '4px',
        boxShadow: ' 0 3px 9px 0 rgba(65, 65, 65, 0.2)',
        border: '1px solid gba(0, 0, 0, 0.3)'
    }

    const checkoutContainerStyles = {
        borderRadius: '4px',
        boxShadow: ' 0 3px 9px 0 rgba(65, 65, 65, 0.2)',
        padding: '8px',
        border: '1px solid gba(0, 0, 0, 0.3)'
    }

    return (
        <div>
            <CustomButton onClick={() => setShowWindow(true)}> Open Window</CustomButton>
            <div style={usageStyles}>
                <span style={{fontWeight:'bold'}}>Usage:</span>
                &nbsp;To open the Window just call the window.CITIZEN_PAY_COMPONENT.startPaymentJourney(transactionId, customerEmailAddress) function <br/>
                <span style={{fontWeight:'bold'}}>Note:</span>
                &nbsp;The transactionId is mandatory and should be passed as a parameter into the startPaymentJourney.

            </div>
            {showWindow ? <JssProvider>
                <NewWindow getWindow={setParent} onUnload={handleOnCloseWindow} on copyStyles={true}>
                    {parent && <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={checkoutContainerStyles}>
                            <CheckoutComponent startPaymentJourney={startPaymentJourney}
                                               styles={styles}
                                               amount={amountToShow}
                                               currency={currencyToShow}
                                               theme={theme}
                                               customerEmailAddress={customerEmailAddress}/>
                        </div>
                    </div>}
                </NewWindow>
            </JssProvider> : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {params: {...state?.ParamsReducer?.params}}
}

const dispatchActionToProps = {
    ...actions
}

export default connect(mapStateToProps, dispatchActionToProps)(WindowedPreviewComponent)
