import defaultColors from "../styles/colors";
import fonts from "../styles/fonts";
import MobileIcon from '../assets/images/svg/mobile_icon'
import Step1Icon from '../assets/images/svg/step_1'
import Step2Icon from '../assets/images/svg/step_2'
import Step3Icon from '../assets/images/svg/step_3'
import TickIcon from '../assets/images/svg/tick'
import WhiteMobileIcon from '../assets/images/svg/white_mobile_icon'
import WhiteStep1Icon from '../assets/images/svg/white_step_1'
import WhiteStep2Icon from '../assets/images/svg/white_step_2'
import WhiteStep3Icon from '../assets/images/svg/white_step_3'
import WhiteTickIcon from '../assets/images/svg/white_tick_icon'

export const APP_MODES = {
    STANDALONE: 'standalone',
    WINDOWED: 'windowed',
    MODAL: 'modal',
    PREVIEW: 'preview'
}

export const PAYMENT_JOURNEY_TYPES = {
    EMAIL: 'email',
    NO_EMAIL: 'no-email',
    QR: 'qr-code'
}

export const THEME_MODES = {
    LIGHT: 'light',
    DARK: 'dark',
    dropdownOptions: ()=>{
        return [{value:'light', name:'Light'}, {value:'dark', name:'Dark'}]
    }
}

export const THEME_STYLES = {
    [THEME_MODES.LIGHT]: {
        container: {
            backgroundColor: defaultColors.$citizenWhite,
            fontFamily: fonts.$citizenFontRegularSF,
            color: undefined
        },
        title: {
            backgroundColor: defaultColors.$citizenWhite,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#373d47'
        },
        description: {
            backgroundColor: '#f0f2f5',
            fontFamily: fonts.$citizenFontRegularSF,
            color: undefined
        },
        steps: {
            backgroundColor: undefined,
            fontFamily: fonts.$citizenFontRegularSF,
            color: undefined
        },
        emailContainer: {
            backgroundColor: undefined,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#2a3649'
        },
        textField: {
            backgroundColor: undefined,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#000000'
        },
        actionText: {
            backgroundColor: '#f0f2f5',
            fontFamily: fonts.$citizenFontRegularSF,
            color: undefined
        },
        button: {
            backgroundColor: defaultColors.$citizenPurple,
            fontFamily: fonts.$citizenFontRegularSF,
            color: defaultColors.$citizenWhite
        }
    },
    [THEME_MODES.DARK]: {
        container: {
            backgroundColor: defaultColors.$citizenBlack,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#ffffff'
        },
        title: {
            backgroundColor: defaultColors.$citizenBlack,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#ffffff'
        },
        description: {
            backgroundColor: '#484848',
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#ffffff'
        },
        steps: {
            backgroundColor: defaultColors.$citizenBlack,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#ffffff'
        },
        emailContainer: {
            backgroundColor: defaultColors.$citizenBlack,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#ffffff'
        },
        textField: {
            backgroundColor: defaultColors.$citizenBlack,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#ffffff'
        },
        actionText: {
            backgroundColor: defaultColors.$citizenBlack,
            fontFamily: fonts.$citizenFontRegularSF,
            color: '#ffffff'
        },
        button: {
            backgroundColor: defaultColors.$citizenPurple,
            fontFamily: fonts.$citizenFontRegularSF,
            color: defaultColors.$citizenWhite
        }
    }
}

export const THEME_ASSETS = {
    [THEME_MODES.LIGHT]: {
        tickIcon: TickIcon,
        step1Icon: Step1Icon,
        step2Icon: Step2Icon,
        step3Icon: Step3Icon,
        mobileIcon: MobileIcon
    },
    [THEME_MODES.DARK]: {
        tickIcon: WhiteTickIcon,
        step1Icon: WhiteStep1Icon,
        step2Icon: WhiteStep2Icon,
        step3Icon: WhiteStep3Icon,
        mobileIcon: WhiteMobileIcon
    }
}

export const CURRENCY_MAP = {
    GBP: {
        symbol: '£',
        country: 'gb'
    },
    EUR: {
        symbol: '€',
        country: 'eu'
    },
    USD: {
        symbol: '$',
        country: 'USA'
    }
}
