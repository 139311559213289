import React, {useState} from "react";
import Modal from "../Components/Modal/Modal";
import ModalContainer from "./Modal/ModalContainer";

let ModalApp = (props)=>{
    const [open, setOpen] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState({})

    window.CITIZEN_PAY_COMPONENT.startPayInJourney = (transactionId, customerEmailAddress) =>{
        setOpen(true)
        setPaymentDetails({transactionId, customerEmailAddress})
    }

    const handleCloseModal = () => {
        setOpen(false)
    }

    return <Modal open={open} onClose={handleCloseModal}>
        <ModalContainer {...props}
                        closeModal={handleCloseModal}
                        transactionId={paymentDetails.transactionId}
                        customerEmailAddress={paymentDetails.customerEmailAddress}/>
    </Modal>
}

export default ModalApp
