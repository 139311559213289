import React from 'react';
import PropTypes from 'prop-types'
import BanksIcon from '../assets/images/svg/banks'
import CustomButton from "./CustomButtons/CustomButton";
import {generateStyles} from "../utils/commonUtils";
import {CURRENCY_MAP, PAYMENT_JOURNEY_TYPES, THEME_ASSETS, THEME_MODES} from "../utils/Enums";
import useStyles from '../styles'

const CheckoutComponent = ({startPaymentJourney, amount, currency, styles, loading, theme, paymentType}) => {

    const classes = useStyles()

    const containerStyles = generateStyles(styles.container)
    const titleStyles =  generateStyles(styles.title)
    const descriptionStyles = generateStyles(styles.description)
    const stepsStyles = generateStyles(styles.steps)
    const actionTextStyles = generateStyles(styles.actionText)
    const buttonStyles = generateStyles(styles.button)

    const TickIcon = THEME_ASSETS[theme].tickIcon
    const Step1Icon = THEME_ASSETS[theme].step1Icon
    const Step2Icon = THEME_ASSETS[theme].step2Icon
    const MobileIcon = THEME_ASSETS[theme].mobileIcon

    let descriptionPerPaymentType

    switch (paymentType) {
        case PAYMENT_JOURNEY_TYPES.NO_EMAIL:
            descriptionPerPaymentType =  <div className={classes.actionTextContainer}>
                Pressing continue with the payment will securely transfer you to select your bank.
            </div>
            break
        case PAYMENT_JOURNEY_TYPES.QR:
            descriptionPerPaymentType =  <div className={classes.actionTextContainer}>
                <MobileIcon className={classes.mobileIcon}/>
                <span>Scan the QR code with your <span className={classes.bold}>phone</span> to pay faster & more easily via your bank app</span>
            </div>
            break
        default:
            descriptionPerPaymentType = <div className={classes.actionTextContainer}>
                <MobileIcon className={classes.mobileIcon}/>
                <span>Open the email on your <span className={classes.bold}>phone</span> to pay faster & more easily via your bank app (recommended)</span>
            </div>
    }

    return (
        <div className={classes.container} style={containerStyles}>
            <div className={classes.title} style={titleStyles}>Pay direct from your bank</div>
            <div className={classes.bankIcons}>
                <BanksIcon/>
            </div>
            <div className={classes.description} style={descriptionStyles}>
                <div className={classes.descriptionTip}>
                    <TickIcon/>
                    <span> <span className={classes.bold}>No</span> need for your card or account number & sort code</span>
                </div>
                <div className={classes.descriptionTip}>
                    <TickIcon/>
                    <span> <span className={classes.bold}>No</span> new accounts to create</span>
                </div>
                <div className={classes.descriptionTip}>
                    <TickIcon/>
                    <span> <span className={classes.bold}>Protected</span> by your own banks security</span>
                </div>
            </div>
            <div className={classes.steps} style={stepsStyles}>
                <div className={classes.step}>
                    <Step1Icon/>
                    <span>Select your bank</span>
                </div>
                <div className={classes.step}>
                     <Step2Icon/>
                    <span>Approve payment in your online banking</span>
                </div>
            </div>

            <div className={classes.actionContainer} style={actionTextStyles}>
                {descriptionPerPaymentType}
                <CustomButton style={{width:'100%', border:0, ...buttonStyles}} disabled={loading} onClick={startPaymentJourney}>
                     {amount && !isNaN(amount)?
                         `Continue with ${CURRENCY_MAP[currency] ? CURRENCY_MAP[currency].symbol : '£'}${amount} payment`:
                         'Continue with the payment'}
                </CustomButton>
            </div>
        </div>
    )
};

CheckoutComponent.propTypes = {
    startPaymentJourney: PropTypes.func,
    customerEmailAddress: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    styles: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    theme: PropTypes.oneOf(Object.values(THEME_MODES))
}

CheckoutComponent.defaultProps = {
    styles: {},
    error: '',
    theme: THEME_MODES.LIGHT,
    currency: 'GBP'
}

export default CheckoutComponent;
