import React, {useEffect, useState} from 'react';
import {setupPayment, setupScript} from "../../utils/commonUtils";
import CheckoutComponent from "../../Components/CheckoutComponent";

const WindowView = ({paymentDetails, parent, styles, theme, transactionId, paymentType, customerEmailAddress}) => {
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)

    const publicApiKey = paymentDetails?.publicApiKey

    useEffect(() => {
        if (!hasLoadedScripts && publicApiKey && parent) {
            setHasLoadedScrips(true)
            setupScript(parent, publicApiKey)
        }
    }, [parent, hasLoadedScripts, publicApiKey])

    if (!paymentDetails) {
        console.error('PaymentDetails field is mandatory.')
        return null
    }

    if (paymentDetails && !publicApiKey) {
        console.error('Public Api key is mandatory.')
    }

    if (!parent) {
        console.error('New window is not initialized yet.')
        return null
    }

    const { amountToShow, currencyToShow, successCallback, failureCallback} = paymentDetails
    const _customerEmailAddress = customerEmailAddress ? customerEmailAddress : paymentDetails.customerEmailAddress

    const successCallbackWrapper = () => {
        successCallback && successCallback();
        parent.close()
    }

    const failureCallbackWrapper = () => {
        failureCallback && failureCallback();
        parent.close()
    }

    let startPaymentJourney = () => {
        if (publicApiKey) {
            const _transactionId = transactionId ? transactionId : paymentDetails.transactionId

            const newPaymentDetails = {
                ...paymentDetails,
                customerEmailAddress: _customerEmailAddress,
                transactionId: _transactionId,
                successCallback: successCallbackWrapper,
                failureCallback: failureCallbackWrapper
            }

            setupPayment(parent, _customerEmailAddress, newPaymentDetails, paymentType)

        } else {
            console.error('Public Api key is missing.')
        }
    }

    return (
        <div style={{display:'flex', justifyContent: 'center'}}>
            <div style={{borderRadius:'4px', boxShadow: ' 0 3px 9px 0 rgba(65, 65, 65, 0.2)', padding:'8px', border:'1px solid gba(0, 0, 0, 0.3)'}}>
            <CheckoutComponent startPaymentJourney={startPaymentJourney}
                               amount={amountToShow}
                               currency={currencyToShow}
                               styles={styles}
                               theme={theme}
                               paymentType={paymentType}
                               customerEmailAddress={_customerEmailAddress}/>
            </div>
        </div>
    );
};

export default WindowView;
