import React, {useState} from "react";
import {JssProvider} from "react-jss";
import NewWindow from "../Components/NewWindow";
import {Provider} from "react-redux";
import store from "../store";
import WindowContainer from "./Windowed/WindowContainer";

const WindowedApp = () =>{
    const [newWindow, setWindow] = useState(null)
    const [showWindow, setShowWindow] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState({})

    window.CITIZEN_PAY_COMPONENT.startPayInJourney = (transactionId, customerEmailAddress) =>{
        setShowWindow(true)

        setPaymentDetails({transactionId, customerEmailAddress})
    }

    const handleCloseWindow = () =>{
        setShowWindow(false)
        setWindow(null)
    }

    return <JssProvider>
        {showWindow && <NewWindow getWindow={setWindow} onUnload={handleCloseWindow} copyStyles={true}>
            <Provider store={store}>
                <WindowContainer parent={newWindow}
                                 transactionId={paymentDetails.transactionId}
                                 customerEmailAddress={paymentDetails.customerEmailAddress}/>
            </Provider>
        </NewWindow>}
    </JssProvider>
}

export default WindowedApp
