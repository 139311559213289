import React, {useState} from 'react';
import {APP_MODES, THEME_MODES, THEME_STYLES} from "../../utils/Enums";
import RadioButton from "../../Components/CustomInputComponents/RadioButton";
import Select from "../../Components/CustomInputComponents/Select";
import StylePicker from "./components/StylePicker";
import ReactJson from "react-json-view";
import StandalonePreviewComponent from "./components/StandalonePreviewComponent";
import ModalPreviewComponent from "./components/ModalPreviewComponent";
import WindowedPreviewComponent from "./components/WindowedPreviewComponent";
import useStyles from '../../styles'
import {filterStylesByTheme} from "../../utils/commonUtils";

const componentToRenderByAppMode = (appMode) => {
    switch (appMode) {
        case APP_MODES.STANDALONE:
            return <StandalonePreviewComponent/>
        case APP_MODES.MODAL:
            return <ModalPreviewComponent/>
        case APP_MODES.WINDOWED:
            return <WindowedPreviewComponent/>
        default:
            return null
    }
}

const PreviewView = (props) => {
    const {params, setStyles, changeTheme} = props
    const [mode, setMode] = useState(APP_MODES.STANDALONE)
    const classes = useStyles()

    const componentToRender = componentToRenderByAppMode(mode)

    const handleChangeStyle = (style, scope, name) => {
        setStyles({
            ...params.styles,
            [scope]: {...params.styles[scope], [name]: style}
        })
    }

    const handleChangeTheme = ({target: {value}}) => {
        changeTheme(value)
    }

    const filteredStyles = filterStylesByTheme(params.theme, params.styles)

    return (
        <div className={classes.previewContainer}>

            <div className={classes.previewToolsContainer}>
                <div className={classes.previewModeContainer}>
                    <RadioButton onChange={() =>setMode(APP_MODES.STANDALONE)}
                                 checked={mode===APP_MODES.STANDALONE}
                                 label={'Standalone'}/>
                    <RadioButton onChange={() =>setMode(APP_MODES.MODAL)}
                                 checked={mode===APP_MODES.MODAL}
                                 label={'Modal'}/>
                    <RadioButton onChange={() =>setMode(APP_MODES.WINDOWED)}
                                 checked={mode===APP_MODES.WINDOWED}
                                 label={'Windowed'}/>
                </div>

                <div className={classes.previewThemeContainer}>
                    <div className={classes.previewThemeTitle}>Theme</div>
                        <div className={classes.previewThemeSelectorContainer}>
                            <Select value={params.theme}
                                    name={'theme'}
                                    placeholder={'Select theme'}
                                    hasEmptyOption={false}
                                    onChange={handleChangeTheme}
                                    options={THEME_MODES.dropdownOptions()}/>
                        </div>
                </div>

                <div>
                    <StylePicker values={params?.styles?.container}
                                 onChange={handleChangeStyle}
                                 title={'Container'}
                                 scope={'container'}/>
                    <StylePicker values={params?.styles?.title}
                                 onChange={handleChangeStyle}
                                 title={'Title'}
                                 scope={'title'}/>
                    <StylePicker values={params?.styles?.description}
                                 onChange={handleChangeStyle}
                                 title={'Description'}
                                 scope={'description'}/>
                    <StylePicker values={params?.styles?.steps}
                                 onChange={handleChangeStyle}
                                 title={'Steps'}
                                 scope={'steps'}/>
                    <StylePicker values={params?.styles?.actionText}
                                 onChange={handleChangeStyle}
                                 title={'ActionText'}
                                 scope={'actionText'}/>
                    <StylePicker values={params?.styles?.button}
                                 onChange={handleChangeStyle}
                                 title={'Button'}
                                 scope={'button'}/>
                </div>

            </div>

            <div className={classes.previewCheckoutComponentContainer}>
                {componentToRender}
            </div>

            <div className={classes.previewJson}>
                <ReactJson shouldCollapse={false}
                           name={false}
                           src={{
                               mode,
                               theme: params.theme,
                               paymentDetails: params.paymentDetails,
                               styles: filteredStyles
                           }}/>
            </div>
        </div>
    );
};

export default PreviewView;
