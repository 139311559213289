import React from 'react'
import StandaloneContainer from "./Standalone/StandaloneContainer";
import {useState} from "react";

const StandaloneApp = () => {
    const [paymentDetails, setPaymentDetails] = useState({})

    window.CITIZEN_PAY_COMPONENT.startPayInJourney = (transactionId, customerEmailAddress) =>{
        setPaymentDetails({transactionId, customerEmailAddress})
    }

    return <StandaloneContainer transactionId={paymentDetails.transactionId}
                                customerEmailAddress={paymentDetails.customerEmailAddress}/>
}

export default StandaloneApp
