import {PAYMENT_JOURNEY_TYPES, THEME_STYLES} from "./Enums";

export const generateStyles = (styles = {}, stylesToExtract = ['fontFamily', 'backgroundColor', 'color']) =>{
    let finalStyles = {}
    stylesToExtract.forEach(styleAttribute => {
        if(styles[styleAttribute]){
            finalStyles[styleAttribute] = styles[styleAttribute]
        }
    })
    return finalStyles
}

export const throttle = (callback, delay) => {
    let throttleTimeout = null;
    let storedEvent = null;

    const throttledEventHandler = event => {
        storedEvent = event;

        const shouldHandleEvent = !throttleTimeout;

        if (shouldHandleEvent) {
            callback(storedEvent);

            storedEvent = null;

            throttleTimeout = setTimeout(() => {
                throttleTimeout = null;

                if (storedEvent) {
                    throttledEventHandler(storedEvent);
                }
            }, delay);
        }
    };

    return throttledEventHandler;
};

export function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        let context = this;
        let args = arguments;

        let later = function() {
            timeout = null;
            func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export const determineSticky = (currentReference, setStyle) =>{
    if(currentReference) {
           let bounding = currentReference.getBoundingClientRect()
           let parentBounding = currentReference?.parentNode?.getBoundingClientRect()

        if((parentBounding?.height + parentBounding?.y) < bounding.bottom) {
               setStyle({position: 'absolute',bottom:0})
           } else if(bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)){
               setStyle({position: 'fixed'})
           }
    }
}

export const validateEmail = (value) => value?
    !!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
    : false

export const setupPayment = (parent, userEmail, paymentDetails, paymentType) => {
    switch (paymentType) {
        case PAYMENT_JOURNEY_TYPES.EMAIL:
        default:
            setupEmailPayment(parent, userEmail, paymentDetails)
            break
        case PAYMENT_JOURNEY_TYPES.NO_EMAIL:
            setupNonEmailPayment(parent, paymentDetails.transactionId)
            break
        case PAYMENT_JOURNEY_TYPES.QR:
            setupQrPayment(parent, userEmail, paymentDetails)
            break
    }
}

export const setupNonEmailPayment =  (parent, transactionId) => {
    parent.CITIZEN_PAYIN.startPayInJourney(transactionId);
}

export const setupEmailPayment = (parent, userEmail, paymentDetails) => {
    const {successCallback, failureCallback, transactionId} = paymentDetails

    const options = {
        customerEmailAddress: userEmail,
        successCallback: successCallback,
        failureCallback: failureCallback
    }

    parent.CITIZEN_PAYIN.startEmailPayInJourney(transactionId, options);
}

export const setupQrPayment = (parent, userEmail, paymentDetails) => {
    const {successCallback, failureCallback, transactionId} = paymentDetails

    const options = {
        customerEmailAddress: userEmail,
        successCallback: successCallback,
        failureCallback: failureCallback
    }

    parent.CITIZEN_PAYIN.startQRCodePayInJourney(transactionId, options);
}
export const setupScript = (parent, publicApiKey) =>{
    const scriptSrc = `${process.env.SDK_BASE_URL}/sdk/sdk-payin.js`

    if(publicApiKey && !isScriptAppended(scriptSrc)) {
        let script = parent.document.createElement('script');
        script.src = scriptSrc;
        script.onload = function () {
            parent.CITIZEN_PAYIN.init({
                publicApiKey,
            })
        };
        parent.document.body.appendChild(script);
    }
}

const isScriptAppended = (url) => {
    let exists = false
    let scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length;  i++) {
        if (scripts[i].src === url){
            exists = true
        }
    }

    return exists
}

export const filterStylesByTheme = (theme, styles) =>{
    const themeStyles = THEME_STYLES[theme]
    const distinctStyles = {}

    Object.entries(styles).forEach(([key, value])=>{
        Object.entries(value).forEach(([attributeKey, attributeValue]) => {
            if(styles[key] && themeStyles[key]) {
                if (styles[key][attributeKey] !== themeStyles[key][attributeKey]) {
                    distinctStyles[key] = {}
                    distinctStyles[key][attributeKey] = attributeValue
                }
            }
        })
    })

    return distinctStyles
}
