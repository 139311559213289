import React, {useEffect, useState} from 'react';
import {setupPayment, setupScript} from "../../utils/commonUtils";
import CheckoutComponent from "../../Components/CheckoutComponent";

const StandaloneView = ({paymentDetails, styles, theme, paymentType, transactionId, customerEmailAddress}) => {
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)

    const publicApiKey = paymentDetails?.publicApiKey
    const _customerEmailAddress = customerEmailAddress ? customerEmailAddress : paymentDetails?.customerEmailAddress
    const amountToShow = paymentDetails?.amountToShow
    const currencyToShow = paymentDetails?.currencyToShow

    if (!paymentDetails) {
        console.error('PaymentDetails field is mandatory.')
    }

    if (paymentDetails && !publicApiKey) {
        console.error('Public Api key is mandatory.')
    }

    useEffect(() => {
        if (!hasLoadedScripts && publicApiKey) {
            setHasLoadedScrips(true)
            setupScript(window, publicApiKey)
        }
    }, [hasLoadedScripts, publicApiKey])

    let startPaymentJourney = () => {
        if (publicApiKey) {
            const _transactionId = transactionId ? transactionId : paymentDetails?.transactionId

            const newPaymentDetails = {
                ...paymentDetails,
                customerEmailAddress: _customerEmailAddress,
                transactionId: _transactionId
            }
            setupPayment(window, _customerEmailAddress, newPaymentDetails, paymentType)

        } else {
            console.error('Public Api key is missing.')
        }
    }

    return (<CheckoutComponent startPaymentJourney={startPaymentJourney}
                               styles={styles}
                               amount={amountToShow}
                               currency={currencyToShow}
                               theme={theme}
                               paymentType={paymentType}
                               customerEmailAddress={_customerEmailAddress}/>)

};

export default StandaloneView;
