import React from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import {setupConfig} from "./utils/setupConfig";
import {Provider} from 'react-redux'
import store from './store'
import {APP_MODES} from "./utils/Enums";
import PreviewContainer from "./Views/Preview/PreviewContainer";
import ModalApp from "./Views/ModalApp";
import WindowedApp from "./Views/WindowApp";
import StandaloneApp from "./Views/StandaloneApp";


const renderApp = (mode) => {
    switch (mode) {
        case APP_MODES.STANDALONE:
            renderStandaloneApp()
            break
        case APP_MODES.MODAL:
            renderModalApp()
            break
        case APP_MODES.WINDOWED:
            renderWindowedApp()
            break
        case APP_MODES.PREVIEW:
            renderPreview()
            break
        default:
            renderStandaloneApp()
    }
}

const renderStandaloneApp = () => {
    let container = document.getElementById('citizen-pay-component');
    const root = createRoot(container)
    if(root) {
        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <StandaloneApp/>
                </Provider>
            </React.StrictMode>
        );
    }
}

const renderWindowedApp = () => {
    let container = document.getElementById('citizen-pay-component');
    const root = createRoot(container)
    if(root) {
        root.render(
            <React.StrictMode>
                <WindowedApp/>
            </React.StrictMode>,
            root
        );
    }
}

const renderModalApp = () => {

    let container = document.getElementById('citizen-pay-component');
    const root = createRoot(container)
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <ModalApp/>
            </Provider>
        </React.StrictMode>,
        root
    );
}

const renderPreview = () =>{
    let container = document.getElementById('citizen-pay-component');
    const root = createRoot(container)
    if(root) {
        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <PreviewContainer/>
                </Provider>
            </React.StrictMode>,
            root
        );
    }

}

setupConfig(store, renderApp).then(() => {
    let intervalId
    if(window.citizenPaymentAsyncInit) {
        window.citizenPaymentAsyncInit()
    } else {
        intervalId = setInterval(()=>{
            if(window.citizenPaymentAsyncInit){
                clearInterval(intervalId)
                window.citizenPaymentAsyncInit()
            }
        },1000)
    }
})


if(process.env.NODE_ENV === 'development'){
    renderApp(APP_MODES.PREVIEW)
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
