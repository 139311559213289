import {connect} from "react-redux"
import StandaloneView from "./StandaloneView"

const mapStateToProps = ({ParamsReducer:{params}}, ownProps) => {
    if(params){
        return {...params, ...ownProps}
    }
    return { paymentDetails:{}, ...ownProps}
}

export default connect(mapStateToProps)(StandaloneView)
