import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import CheckoutComponent from "../../../Components/CheckoutComponent";
import {actions} from "../../../state/ParamsState";
import {determineSticky, throttle} from "../../../utils/commonUtils";

const StandalonePreviewComponent = (props) => {
    const [ref, setRef] = useState(null)
    const [stickyStyle, setStickyStyle] = useState({position: 'fixed'})
    const [hasEventListener, setHasEventListener] = useState(false)

    useEffect(() => {
        if (!hasEventListener && ref) {
            if(window.innerWidth > 624) {
                window.addEventListener('scroll', throttle(() => {
                    determineSticky(ref, setStickyStyle)
                }, 100))
                setHasEventListener(true)
            } else {
                setStickyStyle({position: 'initial'})
            }
        }
        return () => {
            if(window.innerWidth > 624) {
                window.removeEventListener('scroll', throttle(() => {
                    determineSticky(ref, setStickyStyle)
                }, 100))
            }
        }
    }, [hasEventListener, ref])

    const {params} = props

    if (!params) {
        return null
    }

    const {paymentDetails, styles, theme} = params

    if(!paymentDetails) {
        console.error('Payment details are mandatory.')
        return null
    }

    const {customerEmailAddress, amountToShow, currencyToShow} = paymentDetails

    let startPaymentJourney = () => {}

    return (
        <div ref={setRef} style={stickyStyle}>
            <CheckoutComponent startPaymentJourney={startPaymentJourney}
                               styles={{...styles}}
                               amount={amountToShow}
                               currency={currencyToShow}
                               theme={theme}
                               customerEmailAddress={customerEmailAddress}/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {params: {...state?.ParamsReducer?.params}}
}

const dispatchActionToProps = {
    ...actions
}

export default connect(mapStateToProps, dispatchActionToProps)(StandalonePreviewComponent)
